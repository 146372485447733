<template>
  <div class="check_page">
  <!-- 待审核 -->
    <div v-if="checkStatus==0">
      <div class="check_img">
          <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/checkProcess.png" alt="">
      </div>
      <div class="check_txt">
          <span>提交成功</span>
      </div>
      <el-button style="width:128px;height:48px;" type="primary" @click="reedit" >重新编辑</el-button>
      <el-button style="width:128px;height:48px;" v-if="record_details.id" :loading="down_loading" @click="downForm" >下载表单</el-button>
    </div>
  <!-- 审核成功 -->
    <div v-else>
      <div class="check_img">
        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/checkSuccess.png" alt="">
      </div>
      <div class="check_txt">
        <span>审核成功</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checkStatus: {
      type: Number | String,
      default:0,
    },
    record_details:{
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      down_loading:false,
    };
  },
  methods: {
    reedit() {
      this.$emit('reedit',{})
    },
    // 下载表单
    downForm(){
      this.down_loading=true
       let params = {
        id: this.record_details.id,
        source: this.PJSource
      };
      this.$store.dispatch(
        "API_company/company_record_download",
        params
      ).then((res) => {
        this.down_loading=false
        if (res.success) {
          this.$message({
            type: "success",
            showClose: true,
            message: "下载成功",
          });
          window.open(res.data);
        }
      }).catch(()=>{
        this.down_loading=false
      })
    },
  },
};
</script>

<style scoped lang="less">
.check_page {
  margin: 0 auto;
  width: 500px;
  text-align: center;
  overflow: hidden;

  .title {
    font-size: 26px;
    font-weight: 700;
    margin: 15px 0 35px 0;
    span {
        border-bottom: 2px solid #000;
        margin: 0 5px;
    }
  }

  .check_img {
      margin-bottom: 32px;
      img {
          width: 234px;
          height: 171px;
      }
  }

  .check_txt {
      margin-bottom: 40px;
      span {
        font-size: 24px;
        font-weight: 700;
      }
  }

  // .fail_cause {
  //     display: flex;
  //     font-size: 14px;
  //     &>div:nth-child(1) {
  //         width: 60px;
  //         color: #666;
  //     }
  //     &>div:nth-child(2) {
  //         width: 440px;
  //         color: #666;
  //         text-align: left;
  //         margin-bottom: 40px;
  //     }
  // }

  
}
</style>
