<template>
  <div class="initiation">
    <div class="rTit">海派汇（{{form.year}}年度）业务备案表</div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="top"
      class="demo-ruleForm"
    >
      <!-- 企业信息 -->
      <el-row>
        <el-form-item>
          <div class="form-title">
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/qiye_icon.png"
              alt=""
            />
            <span>企业信息</span>
          </div>
        </el-form-item>
        <el-row >
          <el-row :gutter="80">
              <el-col :span="12">
            <el-form-item label="公司全称:" prop="company_name">
              <el-input
                placeholder="输入公司全称"
                disabled
                v-model="form.company_name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="会员编号:" prop="vip_num">
              <el-input
                placeholder="输入会员编号"
                disabled
                v-model="form.vip_num"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="办公地址:" prop="address">
              <el-input
                placeholder="输入办公地址"
                :disabled="disabled"
                v-model="form.address"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业电话:" prop="company_telephone">
              <el-input
                placeholder="输入企业电话"
                :disabled="disabled"
                v-model="form.company_telephone"
              ></el-input>
            </el-form-item>
          </el-col>
          </el-row>
          
          
            <el-row :gutter="20" style="">
              <el-col :span="4">
                <el-form-item label="主要负责人:" prop="manager_name">
                  <el-input
                    placeholder="输入主要负责人"
                    :disabled="disabled"
                    v-model="form.manager_name"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="主要负责人职务:" prop="manager_job">
                  <el-input
                    placeholder="输入主要负责人职务"
                    :disabled="disabled"
                    v-model="form.manager_job"
                  ></el-input>
                </el-form-item>
              </el-col>
              
              <el-col :span="4">
                <el-form-item label="主要负责人手机:" prop="manager_mobile">
                  <el-input
                    placeholder="输入主要负责人手机"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    :disabled="disabled"
                    v-model="form.manager_mobile"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="主要负责人邮箱:" prop="manager_email">
                  <el-input
                    placeholder="输入主要负责人邮箱"
                    :disabled="disabled"
                    v-model="form.manager_email"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="主要负责人微信:" >
                  <el-input
                    placeholder="输入主要负责人微信"
                    :disabled="disabled"
                    v-model="form.manager_wechat"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          
            <el-row :gutter="20" >
              <el-col :span="4">
                <el-form-item label="联系人:" prop="contacts_name">
                  <el-input
                    placeholder="输入联系人"
                    :disabled="disabled"
                    v-model="form.contacts_name"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="联系人职务:" prop="contacts_job">
                  <el-input
                    placeholder="输入联系人职务"
                    :disabled="disabled"
                    v-model="form.contacts_job"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="联系人手机:" prop="contacts_mobile">
                  <el-input
                    placeholder="输入联系人手机"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    :disabled="disabled"
                    v-model="form.contacts_mobile"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="联系人邮箱:" prop="contacts_email">
                  <el-input
                    placeholder="输入联系人邮箱"
                    :disabled="disabled"
                    v-model="form.contacts_email"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="联系人微信:">
                  <el-input
                    placeholder="输入联系人微信"
                    :disabled="disabled"
                    v-model="form.contacts_wechat"
                  ></el-input>
                </el-form-item>
              </el-col>

            </el-row>
        
          <el-col :span="24">
            <el-form-item label="业务范围:" prop="transport_type">
              <el-cascader
                style="width:100%"
                v-model="form.transport_type"
                :options="transport_typeArr"
                :props="{ expandTrigger: 'hover',multiple: true }"
                @change="handleChange_one">
              </el-cascader>

              <!-- <el-radio-group v-model="form.transport_type">
                <el-radio v-for="(item,i) in transport_typeArr" :key='i' :label="item.label">{{ item.label }}</el-radio>
              </el-radio-group> -->
            </el-form-item>
          </el-col>

          <el-col :span="24" v-if="form.company_qualification">
            <el-form-item label="航线:" prop="company_qualification">
              <el-cascader
                style="width:100%"
                v-model="form.company_qualification"
                :options="company_qualificationArr"
                :props="{ expandTrigger: 'hover',multiple: true }"
                @change="handleChange_two">
              </el-cascader>
              <!-- <el-checkbox-group v-model="form.company_qualification">
                <el-checkbox 
                  v-for="(item) in company_qualificationArr" 
                  :key='item.value' 
                  :label="item.label">
                    {{ item.label }}
                </el-checkbox>
              </el-checkbox-group> -->
            </el-form-item>
          </el-col> 
        </el-row>
      </el-row>
      
       <!-- 人事信息  class="form-piece-row"-->
      
      
      <el-row>
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="年末职工人数(人):" prop="workers_num">
              <el-input
                placeholder="输入职工人数(人)"
                oninput="value=value.replace(/[^\d]/g,'')"
                :disabled="disabled"
                v-model="form.workers_num"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="取得国际货代资格证书人数（人）:">
              <el-input
                placeholder="输入取得国际货代资格证书人数"
                oninput="value=value.replace(/[^\d]/g,'')"
                :disabled="disabled"
                v-model="form.certificate_num"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="货运车辆(吨):" prop="carloads">
              <el-input
                placeholder="输入货运车辆(吨)"
                oninput="value=value.replace(/[^\d]/g,'')"
                :disabled="disabled"
                v-model="form.carloads"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="其中集装箱卡车(标准箱):">
              <el-input
                placeholder="输入其中集装箱卡车(标准箱)"
                oninput="value=value.replace(/[^\d]/g,'')"
                :disabled="disabled"
                v-model="form.box_num"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="自有仓库(平方米):" >
              <el-input
                placeholder="输入自有仓库(平方米)"
                oninput="value=value.replace(/[^\d]/g,'')"
                :disabled="disabled"
                v-model="form.warehouse_area"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="其中保税、监管库(平方米):">
              <el-input
                placeholder="其中保税、监管库(平方米)"
                :disabled="disabled"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="form.bonded_warehouse_area"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="铁路专用线(条):" >
              <el-input
                placeholder="输入铁路专用线(条)"
                oninput="value=value.replace(/[^\d]/g,'')"
                :disabled="disabled"
                v-model="form.railway_siding"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="物流计算机信息管理系统(套):">
              <el-input
                placeholder="输入物流计算机信息管理系统(套)"
                oninput="value=value.replace(/[^\d]/g,'')"
                :disabled="disabled"
                v-model="form.management_system"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="海关注册登记证书号:" >
              <el-input
                placeholder="输入海关注册登记证书号"
                :disabled="disabled"
                v-model="form.registration_certificate"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商检报检单位登记号:">
              <el-input
                placeholder="输入商检报检单位登记号"
                :disabled="disabled"
                v-model="form.inspection_register"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-row>
      <el-form-item>
          <div class="form-title">
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/yewu_icon.png"
              alt=""
            />
            <span>年度经营情况</span>
          </div>
        </el-form-item>
      <table class="mytable">
        <tbody>
          <tr>
            <td rowspan="2">运输方式</td>
            <td colspan="3">全年出口</td>
            <td colspan="3">全年进口</td>
          </tr>
          <tr>
            <td class="tdWidth">散货(吨)</td>
            <td class="tdWidth">集装箱货物(标准箱)</td>
            <td class="tdWidth">营业额(万元人民币)</td>
            <td class="tdWidth">散货(吨)</td>
            <td class="tdWidth">集装箱货物(标准箱)</td>
            <td class="tdWidth">营业额(万元人民币)</td>
          </tr>
          <tr>
            <td>海运</td>
            <td><el-input oninput ="value=value.replace(/[^0-9.]/g,'')" v-model="form.sea_out_bulk_cargo" size="mini"></el-input></td>
            <td><el-input oninput="value=value.replace(/[^\d]/g,'')" v-model="form.sea_out_box_num" size="mini"></el-input></td>
            <td><el-input oninput ="value=value.replace(/[^0-9.]/g,'')" v-model="form.sea_out_turnover" size="mini"></el-input></td>
            <td><el-input oninput ="value=value.replace(/[^0-9.]/g,'')" v-model="form.sea_import_bulk_cargo" size="mini"></el-input></td>
            <td><el-input oninput="value=value.replace(/[^\d]/g,'')" v-model="form.sea_import_box_num" size="mini"></el-input></td>
            <td><el-input oninput ="value=value.replace(/[^0-9.]/g,'')" v-model="form.sea_import_turnover" size="mini"></el-input></td>
          </tr>
          <tr>
            <td>陆运</td>
            <td><el-input oninput ="value=value.replace(/[^0-9.]/g,'')" v-model="form.land_out_bulk_cargo" size="mini"></el-input></td>
            <td><el-input oninput="value=value.replace(/[^\d]/g,'')" v-model="form.land_out_box_num" size="mini"></el-input></td>
            <td><el-input oninput ="value=value.replace(/[^0-9.]/g,'')" v-model="form.land_out_turnover" size="mini"></el-input></td>
            <td><el-input oninput ="value=value.replace(/[^0-9.]/g,'')" v-model="form.land_import_bulk_cargo" size="mini"></el-input></td>
            <td><el-input oninput="value=value.replace(/[^\d]/g,'')" v-model="form.land_import_box_num" size="mini"></el-input></td>
            <td><el-input oninput ="value=value.replace(/[^0-9.]/g,'')" v-model="form.land_import_turnover" size="mini"></el-input></td>
          </tr>
          <tr>
            <td>空运</td>
            <td><el-input oninput ="value=value.replace(/[^0-9.]/g,'')" v-model="form.air_out_bulk_cargo" size="mini"></el-input></td>
            <td><el-input oninput="value=value.replace(/[^\d]/g,'')" v-model="form.air_out_box_num" size="mini"></el-input></td>
            <td><el-input oninput ="value=value.replace(/[^0-9.]/g,'')" v-model="form.air_out_turnover" size="mini"></el-input></td>
            <td><el-input oninput ="value=value.replace(/[^0-9.]/g,'')" v-model="form.air_import_bulk_cargo" size="mini"></el-input></td>
            <td><el-input oninput="value=value.replace(/[^\d]/g,'')" v-model="form.air_import_box_num" size="mini"></el-input></td>
            <td><el-input oninput ="value=value.replace(/[^0-9.]/g,'')" v-model="form.air_import_turnover" size="mini"></el-input></td>
          </tr>
          <tr>
            <td>快件</td>
            <td colspan="2"><el-input oninput="value=value.replace(/[^\d]/g,'')" v-model="form.express_mail_out" size="mini"></el-input></td>
            <td><el-input oninput ="value=value.replace(/[^0-9.]/g,'')" v-model="form.express_mail_out_turnover" size="mini"></el-input></td>
            <td colspan="2"><el-input oninput="value=value.replace(/[^\d]/g,'')" v-model="form.express_mail_import" size="mini"></el-input></td>
            <td><el-input oninput ="value=value.replace(/[^0-9.]/g,'')" v-model="form.express_mail_import_turnover" size="mini"></el-input></td>
          </tr>
        </tbody>
      </table>

      <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="仓储营业额(万元人民币):" >
              <el-input
                placeholder="输入仓储营业额(万元人民币)"
                oninput ="value=value.replace(/[^0-9.]/g,'')"
                :disabled="disabled"
                v-model="form.storage_turnover"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="从境外取得的营业额(万元人民币):">
              <el-input
                placeholder="输入从境外取得的营业额(万元人民币)"
                oninput ="value=value.replace(/[^0-9.]/g,'')"
                :disabled="disabled"
                v-model="form.from_abroad_turnover"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="其它营业额(万元人民币):" >
              <el-input
                placeholder="输入其它营业额(万元人民币)"
                oninput ="value=value.replace(/[^0-9.]/g,'')"
                :disabled="disabled"
                v-model="form.other_turnover"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年营业总额(万元人民币):" prop="year_turnover">
              <el-input
                placeholder="输入年营业总额(万元人民币)"
                oninput ="value=value.replace(/[^0-9.]/g,'')"
                :disabled="disabled"
                v-model="form.year_turnover"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="年净利润总额（万元人民币）:" prop="year_profit">
              <el-input
                placeholder="输入年净利润总额（万元人民币）"
                oninput ="value=value.replace(/[^0-9.-]/g,'')"
                :disabled="disabled"
                v-model="form.year_profit"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="交纳税金（万元人民币）:" prop="tax">
              <el-input
                placeholder="输入交纳税金（万元人民币）"
                oninput ="value=value.replace(/[^0-9.]/g,'')"
                :disabled="disabled"
                v-model="form.tax"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="80">
          <el-col :span="24">
            <el-form-item label="备注:">
              <!--<el-input-->
              <!--  placeholder="备注"-->
              <!--  :disabled="disabled"-->
              <!--  type="textarea"-->
              <!--  v-model="form.mark"-->
              <!--&gt;</el-input>-->
              <div>
                1、表中年营业总额是指企业向委托方收取的全部费用总和（不扣除向承运人等最终支付的费用），不是缴纳增值税的依据。
              </div>
              <div>
                2、交纳税金=增值税+所得税+附加税。
              </div>
              <div>
                3、仓储：主要指仓储租金收入和劳务费收入。其中劳务费用收入包括：装卸费、出入费、拆箱费、包装整理费等。
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col >
              <div class="btnBox">
                <el-button type="primary" style="width:300px;margin-right:65px" :loading="submit_loading"  @click="submitForm('form')" v-if="!disabled">提交</el-button>
                <!-- <el-button style="width:300px" type="default" v-if="record_details.id"  :loading="down_loading" @click="downForm">下载</el-button> -->
              </div>
          </el-col>
        </el-row>
        
    </el-form>
  </div>
</template>

<script>

import { validateTelephone, validateIdCard } from "~/baseUtils/validate";
import {
  staffsizeList,
  companyTypeList,
  companyNatureList,
  customsClearanceWayList,
  companyScaleList,
  porganizationTypeList,
  porganizationNatureList,
  characteristicsBusinessList,
  companyAdvantageList,
  expectServiceList,
} from "~scb/utils/enumValue";
export default {
  name: "initiation",
  props: {
    submit_loading:{
      type: Boolean,
      default:false,
    },
    form_data: {
      //回显数据
      type: Object,
      default: {},
    },
    record_details:{
      type: Object,
      default: {},
    },
    disabled: {
      //表单输入选择禁用
      type: Boolean,
      default: false,
    },
    recordLoginInfo: {  //登录信息
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      down_loading:false,
      
      cascader_props: { multiple: true },
      form: {  //表单参数
        company_qualification:[]
      },
      transport_typeArr:[
        {
          value: '海运',
          label: '海运',
          children: [
            {
              value: '海运拼箱',
              label: '海运拼箱'
            },
            {
              value: '海运散杂',
              label: '海运散杂'
            },
            {
              value: '海运整箱',
              label: '海运整箱'
            },
            {
              value: '海运进口',
              label: '海运进口'
            },
            {
              value: '海运出口',
              label: '海运出口'
            },
          ]
        },
        {
          label:"空运",
          value:"空运",
          children: [
            {
                label:"空运进口",
                value:"空运进口"
            },
            {
                label:"空运出口",
                value:"空运出口"
            },
            {
                label:"空运危险品",
                value:"空运危险品"
            },
            {
                label:"跨境包裹空运",
                value:"跨境包裹空运"
            },
            {
                label:"国际快递",
                value:"国际快递"
            },
          ]
        },
        {
          label:"陆运",
          value:"陆运",
          children: [
            {
                label:"零担运输",
                value:"零担运输"
            },
            {
                label:"整合运输",
                value:"整合运输"
            },
            {
                label:"特殊货物（危险品、冷链、异形货）",
                value:"特殊货物（危险品、冷链、异形货）"
            }
          ]
        },
        {
          label:"多式联运",
          value:"多式联运",
          children: [
            {
                label:"海铁联运",
                value:"海铁联运"
            },
            {
                label:"中欧班列",
                value:"中欧班列"
            },
            {
                label:"中蒙班列",
                value:"中蒙班列"
            },
            {
                label:"其他",
                value:"其他"
            },
          ]
        },
        {
          label:"堆场仓储",
          value:"堆场仓储",
          children: [
            {
                label:"库内（保税、非保税）",
                value:"库内（保税、非保税）"
            },
            {
                label:"库外（保税、非保税）",
                value:"库外（保税、非保税）"
            },
          ]
        },
        {
          label:"报关",
          value:"报关",
          children: [
            {
                label:"AEO一般认证",
                value:"AEO一般认证"
            },
            {
                label:"AEO高级认证",
                value:"AEO高级认证"
            },
          ]
        },
        {
          label:"境外网络",
          value:"境外网络",
        },
        {
          label:"科技服务",
          value:"科技服务",
          children: [
            {
                label:"货代软件",
                value:"货代软件"
            },
            {
                label:"数据服务商",
                value:"数据服务商"
            },
            {
                label:"接口服务商",
                value:"接口服务商"
            },
            {
                label:"集卡软件（CTMS）",
                value:"集卡软件（CTMS）"
            },
            {
                label:"仓储软件（WMS）",
                value:"仓储软件（WMS）"
            },
            {
                label:"报关软件",
                value:"报关软件"
            },
            {
                label:"贸易软件",
                value:"贸易软件"
            },
            {
                label:"RPA（流程自动化）",
                value:"RPA（流程自动化）"
            },
            {
                label:"微信小程序",
                value:"微信小程序"
            },
            {
                label:"运价软件",
                value:"运价软件"
            },
            {
                label:"自媒体服务",
                value:"自媒体服务"
            },
            {
                label:"货代SAAS平台",
                value:"货代SAAS平台"
            },
            {
                label:"集卡SAAS平台",
                value:"集卡SAAS平台"
            },
            {
                label:"保税仓软件",
                value:"保税仓软件"
            },
            {
                label:"公路运输软件",
                value:"公路运输软件"
            },
            {
                label:"金融服务平台",
                value:"金融服务平台"
            },
          ]
        },
        {
          label:"其他服务",
          value:"其他服务",
          children: [
            {
                label:"保险服务",
                value:"保险服务"
            },
            {
                label:"海运双清",
                value:"海运双清"
            },
            {
                label:"国际搬家",
                value:"国际搬家"
            },
            {
                label:"滚装服务",
                value:"滚装服务"
            },
            {
                label:"证书办理",
                value:"证书办理"
            },
            {
                label:"项目物流",
                value:"项目物流"
            },
            {
                label:"展会运输",
                value:"展会运输"
            },
            {
                label:"FBA",
                value:"FBA"
            },
            {
                label:"SOC箱服务",
                value:"SOC箱服务"
            },
            {
                label:"教育培训",
                value:"教育培训"
            },
            {
                label:"人力资源",
                value:"人力资源"
            },
            {
                label:"财税筹划",
                value:"财税筹划"
            },
          ]
        },
        {
          label:"以上业务范围之外",
          value:"以上业务范围之外",
        },
      ],
      company_qualificationArr:[
        {
          label:"常用航线",
          value:"常用航线",
          children:[
            {
                label:"中国",
                value:"中国"
            },
            {
                label:"日本",
                value:"日本"
            },
            {
                label:"韩国",
                value:"韩国"
            },
            {
                label:"俄远东",
                value:"俄远东"
            },
            {
                label:"东南亚",
                value:"东南亚"
            },
            {
                label:"美国",
                value:"美国"
            },
          ]
        },
        {
          label:"亚洲",
          value:"亚洲",
          children:[
            {
                label:"中国",
                value:"中国"
            },
            {
                label:"日本",
                value:"日本"
            },
            {
                label:"韩国",
                value:"韩国"
            },
            {
                label:"俄远东",
                value:"俄远东"
            },
            {
                label:"东南亚",
                value:"东南亚"
            },
            {
                label:"中亚",
                value:"中亚"
            },
            {
                label:"印巴",
                value:"印巴"
            },
            {
                label:"中东",
                value:"中东"
            },
            {
                label:"红海",
                value:"红海"
            },
          ]
        },
        {
          label:"欧洲",
          value:"欧洲",
          children:[
            {
                label:"欧洲",
                value:"欧洲"
            },
            {
                label:"地中海",
                value:"地中海"
            },
            {
                label:"黑海",
                value:"黑海"
            },
          ]
        },
        {
          label:"北美洲",
          value:"北美洲",
          children:[
            {
                label:"加拿大",
                value:"加拿大"
            },
            {
                label:"美国",
                value:"美国"
            },
          ]
        },
        {
          label:"南美洲",
          value:"南美洲",
          children:[
            {
                label:"加勒比",
                value:"加勒比"
            },
            {
                label:"墨西哥",
                value:"墨西哥"
            },
            {
                label:"南美东",
                value:"南美东"
            },
            {
                label:"南美西",
                value:"南美西"
            },
          ]
        },
        {
          label:"非洲",
          value:"非洲",
          children:[
            {
                label:"北非",
                value:"北非"
            },
            {
                label:"南非",
                value:"南非"
            },
            {
                label:"东非",
                value:"东非"
            },
            {
                label:"西非",
                value:"西非"
            },
          ]
        },
        {
          label:"澳洲&岛国",
          value:"澳洲&岛国",
          children:[
            {
                label:"澳新",
                value:"澳新"
            },
            {
                label:"太平洋岛屿",
                value:"太平洋岛屿"
            },
            {
                label:"大西洋岛屿",
                value:"大西洋岛屿"
            },
          ]
        },
        {
          label:"以上航线之外",
          value:"以上航线之外",
        },
      ],
      rules: {
        company_name: [
          { required: true, message: "请输入公司全称", trigger: "blur" },
        ],
        company_telephone: [
          { required: true, message: "输入企业电话", trigger: "blur" },
        ],
        vip_num:[
          { required: true, message: "输入会员编号", trigger: "blur" },
        ],
        address:[
          { required: true, message: "输入办公地址", trigger: "blur" },
        ],
        manager_name:[
          { required: true, message: "输入主要负责人", trigger: "blur" },
        ],
        manager_job:[
          { required: true, message: "输入主要负责人职务", trigger: "blur" },
        ],
        manager_mobile:[
          {
            required: true,
            validator: async (rule, value, callback) => {
              if (value == "") {
                return callback(new Error('输入主要负责人手机'));
              } else if (!validateTelephone(value)) {
                callback("手机号格式错误");
              } else {
                
              }
            },
            trigger: "blur",
          },
        ],
        manager_email:[
          { required: true, message: "主要负责人邮箱", trigger: "blur" },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: "blur" },
        ],
        contacts_name:[
          { required: true, message: "输入联系人", trigger: "blur" },
        ],
        contacts_job:[
          { required: true, message: "输入联系人职务", trigger: "blur" },
        ],
        contacts_mobile:[
          {
            required: true,
            validator: async (rule, value, callback) => {
              if (value == "") {
                return callback(new Error('联系人手机'));
              } else if (!validateTelephone(value)) {
                callback("手机号格式错误");
              } else {
              }
            },
            trigger: "blur",
          },
        ],
        contacts_email:[
          { required: true, message: "联系人邮箱", trigger: "blur" },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: "blur" },
        ],
        transport_type:[
          { required: true, message: "业务范围", trigger: "change" },
        ],
        company_qualification:[
          { required: true, message: "航线", trigger: "change" },
        ],
        workers_num:[
          { required: true, message: "输入职工人数(人)", trigger: "blur" },
        ],
        year_turnover:[
          { required: true, message: "输入年营业总额", trigger: "blur" },
        ],
        year_profit:[
          { required: true, message: "年净利润总额", trigger: "blur" },
        ],
        tax:[
          { required: true, message: "输入交纳税金", trigger: "blur" },
        ],
      }, //表单验证
      staffsizeList, //企业员工人数
      companyTypeList, //企业类型
      companyNatureList, //企业性质
      customsClearanceWayList, //报关方式
      companyScaleList, //企业规模
      porganizationTypeList, //党组织类型
      porganizationNatureList, //党组织性质
      characteristicsBusinessList, //特色业务
      companyAdvantageList, //企业优势
      expectServiceList, //期望服务

    };
  },
  watch: {
    recordLoginInfo(nv) {
      console.log(nv,"nvnvnvnv");
          this.form.company_name = nv.company_name;
          this.form.vip_num = nv.member_number;
          this.form.manager_name = nv.manager_name
          this.form.manager_job = nv.manager_job
          this.form.manager_mobile = nv.manager_mobile
          this.form.manager_email = nv.manager_email
          this.form.manager_wechat = nv.manager_wechat
          this.form.address = nv.address
          this.form.company_telephone = nv.company_telephone
    },
  },
  mounted() {
    // console.log(this.recordLoginInfo,"父组件传过来的");
    this.form = {
      company_name:"",
      address:"",
      vip_num:"",
      company_telephone:"",
      manager_name:"",
      manager_job:"",
      manager_mobile:"",
      manager_wechat:"",
      manager_email:"",
      contacts_name:"",
      contacts_job:"",
      contacts_mobile:"",
      contacts_wechat:"",
      contacts_email:"",
      transport_type:"",
      company_qualification:[],
      workers_num:"",
      certificate_num:"",
      carloads:"",
      box_num:"",
      warehouse_area:"",
      bonded_warehouse_area:"",
      railway_siding:"",
      management_system:"",
      registration_certificate:"",
      inspection_register:"",
      sea_out_bulk_cargo:"",
      sea_out_box_num:"",
      sea_out_turnover:"",
      land_out_bulk_cargo:"",
      land_out_box_num:"",
      land_out_turnover:"",
      air_out_bulk_cargo:"",
      air_out_box_num:"",
      air_out_turnover:"",
      express_mail_out:"",
      express_mail_out_turnover:"",
      sea_import_bulk_cargo:"",
      sea_import_box_num:"",
      sea_import_turnover:"",
      land_import_bulk_cargo:"",
      land_import_box_num:"",
      land_import_turnover:"",
      air_import_bulk_cargo:"",
      air_import_box_num:"",
      air_import_turnover:"",
      express_mail_import:"",
      express_mail_import_turnover:"",
      storage_turnover:"",
      from_abroad_turnover:"",
      other_turnover:"",
      year_turnover:"",
      year_profit:"",
      tax:"", 
      // mark: "",
      year: moment().subtract(1,'year').year(),
    };
    this.setForm();
  },
  methods: {
    handleChange_one(e){
      // console.log(e);
      e.forEach(item => {
        item.forEach(one => {
          if (one == "以上业务范围之外") {
            this.form.transport_type = [["以上业务范围之外"]]
          }
        });
      });
    },
    handleChange_two(e){
      // console.log(e);
      e.forEach(item => {
        item.forEach(one => {
          if (one == "以上航线之外") {
            this.form.company_qualification = [["以上航线之外"]]
          }
        });
      });
    },
    setForm(){
      if(this.form_data.vip_num){
        this.form=this.form_data
      }else{
        this.$nextTick(()=>{
          this.form.company_name = this.recordLoginInfo.company_name;
          this.form.vip_num = this.recordLoginInfo.member_number;
          this.form.manager_name = this.recordLoginInfo.manager_name
          this.form.manager_job = this.recordLoginInfo.manager_job
          this.form.manager_mobile = this.recordLoginInfo.manager_mobile
          this.form.manager_email = this.recordLoginInfo.manager_email
          this.form.manager_wechat = this.recordLoginInfo.manager_wechat
          this.form.address = this.recordLoginInfo.address
          this.form.company_telephone = this.recordLoginInfo.company_telephone
        })

      }
    },
    // 下载表单
    downForm(){
      this.down_loading=true
       let params = {
        id: this.record_details.id
      };
      this.$store.dispatch(
        "API_company/company_record_download",
        params
      ).then((res) => {
        this.down_loading=false
        if (res.success) {
          this.$message({
            type: "success",
            showClose: true,
            message: "下载成功",
          });
          window.open(res.data);
        }
      }).catch(()=>{
        this.down_loading=false
      })
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("submitForm", this.form);
        } else {
          this.$message.warning("请检查输入项");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.rTit{
    padding: 30px;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    text-align: center;
}
.mytable{
  width: 100%;
  margin-bottom:10px;
  margin-top:10px;
  td{
    padding: 10px;
    border: 1px solid #333333;
    text-align: center;
  }
  .tdWidth{
    width: 160px;
  }
}

.initiation {
  width: 1120px;
  background: #ffffff;
  /deep/.el-form-item__label,
  .radio-title {
    font-size: 16px;
    color: #606266;
  }
  /deep/.el-input__inner {
    font-size: 16px;
  }
  /deep/.el-textarea__inner {
    font-size: 16px;
  }
  /deep/.el-form-item__label {
    padding: 0;
  }
  /deep/.el-form-item {
    margin-bottom: 12px;
  }
  .initiation-top {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 48px;
  }
  .initiation-title {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
  }
  .form-title {
    span {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      vertical-align: middle;
      margin-left: 12px;
    }
  }
  .form-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .row-line {
      width: 1012px;
      height: 0;
      border-bottom: 1px dashed #dddddd;
    }
  }
  .radio-title {
    margin-right: 24px;
  }
  .form-piece-row {
    margin-top: 22px;
  }
  .input-width-all {
    width: 100%;
  }
}
.btnBox{
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}
</style>
