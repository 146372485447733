<template>
  <div class="recordBox">
    <checkStatus v-if="show_time && record_status!=2" :record_details="record_details" :checkStatus='record_status' @reedit='reedit'  />
      <recordForm 
        class="marginAout"
        v-if="show_time && record_status==2" 
        @submitForm="submitForm"
        :form_data="record_form" 
        :record_details="record_details"
        :submit_loading="submit_loading"
        :recordLoginInfo="recordLoginInfo"
      />
  </div>
</template>
<script>
import recordForm from '~hph/components/record/recordForm'
import checkStatus from '~hph/components/record/checkStatus'
export default {
  components:{
    recordForm,
    checkStatus


  },
  data() {
    return {
      submit_loading:false,
      // initiation_status:"",//0---初始状态；1---填表；2---表单审核；3---表单审核失败；4---上传附件；5---附件审核；6---审核通过；7---会员
      // form_data:{},//判断VIP用到
      record_status:2,
      show_time: false,
      record_form:{},
      recordLoginInfo: {},//登录信息
      record_details:{},// 备案详情接口返回的数据
    }
  },
  mounted(){
    // this.recordLoginInfo = {
    //   company_name: this.USER_INFO.company_name_zh
    // }
    this.getCompanyMemberInfo()
    this.company_record_check()
    console.log(this.recordLoginInfo,"在父组件里获取的");
  },
  methods:{
    async getCompanyMemberInfo() {
      let res = await this.$store.dispatch("API_company/getCompanyMemberInfo",{company_id:this.USER_INFO.company_id})
      if(res.success) {
        this.recordLoginInfo = {
          company_name: res.data.name_zh,
          member_number: res.data.aila_no,
          manager_name: res.data.admin_name_zh,
          manager_job: res.data.admin_job_title_zh,
          manager_mobile: res.data.admin_mobile,
          manager_email: res.data.admin_email,
          manager_wechat: res.data.admin_wechat,
          address: res.data.address_zh,
          company_telephone: res.data.telephone
        }
      }
    },
    
    // 重新编辑
    reedit(){
      this.record_status=2
    },
    // 公司备案检查
    async company_record_check(){
      let params={
        source:this.PJSource,
        company_id: this.USER_INFO.company_id
      }
      let res = await this.$store.dispatch("API_company/company_record_check",params) 
      if(res.success){ // 可编辑
        this.company_record_details()
      }else{ // 不可编辑==>审核通过
        this.record_status=1
        this.show_time = true;
      }
    },
    
    // 公司备案详情
    async company_record_details(){
      let params={
        source:this.PJSource,
        company_id: this.USER_INFO.company_id
      }
      let res = await this.$store.dispatch("API_company/company_record_details",params) 
      this.record_status=res.data.audit_status
      if(res.data.record_info){ // 填过信息
        let record_form = JSON.parse(res.data.record_info);
        record_form.year = res.data.year;
        this.record_form = record_form;
        this.record_details=res.data
      }else{ //没有填过信息
        this.record_status=2
      }
      this.show_time = true;
    },
    // 表单提交
    async submitForm(value) {
      // if(!this.USER_INFO.is_admin){
      //   this.dialogVisible = true;
      //   return
      // }
      let year = value.year;
      this.submit_loading=true
      let params={
        year,
        tiffa_company_auth_id: this.USER_INFO.company_id,
        source:this.PJSource,
        record_info:JSON.stringify(value),
        create_form_time:this.$moment().unix(),
      }
      if(this.record_details.id){
        params.id=this.record_details.id
      }
      let res = await this.$store.dispatch("API_company/company_record_save",params) 
      if(res.success){
        this.$message.success("提交成功")
        this.company_record_details()
      }
      this.submit_loading=false
    }
  }
}
</script>
<style lang="less" scoped>
.marginAout{
  margin: auto;
  padding: 0 10px;
}
.recordBox{
  padding: 30px 0;
  position: relative;
}
</style>